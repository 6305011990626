<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/likeListening/top_01.png"
    />
    <div class="content">
      <div class="scroll_tips">
        <ul class="scroll_box">
          <li class="scroll_item">130****6142抢到了</li>
          <li class="scroll_item">155****7394抢到了</li>
          <li class="scroll_item">176****4549抢到了</li>
          <li class="scroll_item">186****5436抢到了</li>
          <li class="scroll_item">175****0054抢到了</li>
          <li class="scroll_item">185****5245抢到了</li>
          <li class="scroll_item">167****6540抢到了</li>
          <li class="scroll_item">131****0974抢到了</li>
        </ul>
      </div>
      <van-form>
        <img
          class="step1"
          v-if="num == 1"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
        />
        <van-field
          v-model="form.phone"
          type="tel"
          maxlength="11"
          placeholder="请输入您的手机号码"
        />
        <img
          class="step2"
          v-if="num == 2"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
        />
        <img
          class="step3"
          v-if="num == 3"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
        />
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入短信验证码"
          class="input-code"
        >
          <template slot="right-icon">
            <div class="code" v-show="sendAuthCode" @click="getAuthCode">
              获取验证码
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>

        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageYDGX/dragon_submit.png"
          class="submit"
          @click="onSubmit"
        />
      </van-form>
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/likeListening/bg_01.png"
      />
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/likeListening/bg_02.png"
      />
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/likeListening/bg_03.png"
      />
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/likeListening/bg_04.png"
      />

    </div>
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";
import { liuliangguo } from "@/utils/common";

export default {
  mixins: [tailJumpMixin],
  data() {
    return {
      form: {
        productNo: "914205202337",
        phone: "",
        characteristic: "",
        marketUrl: "",
        sid: "",
        data: "",
        code: "",
        orderId: "",
      },
      num: 1,
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
    };
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      window.location.replace(
        that.tailJumpUrl(that.link, "LIANTONGRONGHE", that.form.phone, "退出")
      );
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/fusion/LTVideoRingtone/liaoning/cloudAcceleration`;
    },
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  methods: {
    getAuthCode() {
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.yzm_loading = true;
        getVerificationCode(JSON.stringify({ ...this.form, appPackage: navigator.userAgent }))
          .then((res) => {
            this.yzm_loading = false;
            if (res.code != 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败"});
              setTimeout(() => {
                window.location.replace(
                  this.tailJumpUrl(
                    this.link,
                    "LIANTONGRONGHE",
                    this.form.phone,
                    "验证码下发失败"
                  )
                );
              }, 3000);
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          })
          .catch((error) => {
            this.yzm_loading = false;
          });
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.close();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(this.link, "LIANTONGRONGHE", this.form.phone, "失败")
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "left",
          });
          liuliangguo("103811", this.form.phone, "21元联通爱听（含云盘）月包（辽宁）");
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" })
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else {
        // this.visibility = true;
        this.handleSubmit();
      }
    },
     close() {
      this.visibility = false;
      this.$toast.clear();
    },

  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #e23b2a;
  .img {
    width: 100%;
  }

  .content {
    // padding: 0 18px;

    .scroll_tips {
      width: 100%;
      margin: -20px auto 0;
      overflow: hidden;

      .scroll_box {
        height: 56px;
        animation: scroll 10s linear infinite;
        display: flex;
        // transform: translateX(-1680px);

        .scroll_item {
          background-color: rgba(0, 0, 0, 0.44);
          border-radius: 40px;
          white-space: nowrap;
          width: 288px;
          text-align: center;
          font-weight: 700;
          flex-shrink: 0;
          line-height: 56px;
          font-size: 24px;
          color: white;
          margin-left: 16px;
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-1680px);
          }
        }
      }
    }

    ::v-deep .van-form {
      background: transparent;
      position: relative;
      padding: 60px 0 0px;

      .step1,
      .step2,
      .step3 {
        width: 300px;
        position: absolute;
        z-index: 1;
        pointer-events: none;
      }

      .step1 {
        transform: translate(90px, -70px);
      }

      .step2 {
        width: 120px;
        right: 70px;
        top: 156px;
      }

      .step3 {
        width: 310px;
        transform: translate(90px, -8px);
      }

      .van-cell {
        width: 630px;
        height: 90px;
        background-color: #ffffff;
        border-radius: 80px;
        margin: 0 auto;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 0 0 40px;

        &.input-code {
          margin: 44px auto;

          .code {
            width: 186px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            color: #ff7900;
            font-size: 28px;
            font-weight: normal;
            border-left: 2px solid #d1d1d1;
          }
        }

        .van-field__body {
          height: 100%;

          input {
            height: 100%;
          }
        }

        &::after {
          border: none;
        }

        .van-field__control {
          font-size: 28px;
          font-weight: 400;
          &::-webkit-input-placeholder {
            font-size: 28px;
            font-weight: 500;
            color: #999;
          }
        }
      }
      .submit {
        width: 630px;
        display: block;
        margin: 0px auto 60px;
        animation: identifier 1.5s infinite;

        @keyframes identifier {
          0% {
            transform: scale(1);
          }
          25% {
            transform: scale(0.9);
          }
          50% {
            transform: scale(1);
          }
          75% {
            transform: scale(0.9);
          }
        }
      }
    }

    .img {
      box-sizing: border-box;
    }

    .info {
      color: #ffffff;
      padding: 0 36px;

      .title {
        text-align: center;
        font-size: 36px;
        padding: 40px 0;
      }

      .text {
        font-size: 26px;
        font-weight: 500;
        line-height: 46px;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
